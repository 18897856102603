export const kBrandPageContentInfo = {
    data: {
        videoURL:
            "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/brand/wanfei.mp4",
        poster:
            "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/brand/cover.png",
        texts: ["万非，就是芦荟！", "万非，又不只是芦荟！"],

    },
}
