<template>
  <div class="m_page_contianer_for_main">
    <div id="m_brand_logo_content">
      <img id="m_brand_logo" src="@/assets/logo.png" />
    </div>
    <div id="m_brand_video_content">
      <video id="m_brand_video" controls name="media" :poster="data.poster">
        <source :src="data.videoURL" type="video/mp4" />
      </video>
    </div>
    <div id="m_brand_des">
      <div id="m_brand_des_content">
        <img id="m_brand_des_content_icon" src="@/assets/doubleQuotes.png" />
        <div
          v-for="(text, index) in data.texts"
          :key="'text' + index"
          id="m_brand_des_content_text"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { kBrandPageContentInfo } from "@/config/brand";

@Options({
  components: {},
  data() {
    return {
      data: kBrandPageContentInfo.data,
    };
  },
})
export default class BrandPage extends Vue {}
</script>

<style scoped lang="scss">
@import url("../../../less/mobile/page.less");

#m_brand_logo_content {
  width: 100%;
  height: 25vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#m_brand_logo {
  width: calc(192 / 1080 * 100vw);
  height: calc(192 / 1080 * 100vw);
  object-fit: contain;
}

#m_brand_video_content {
  width: 100%;
  height: calc(608 / 1080 * 100vw);
}

#m_brand_video {
  width: 100%;
  height: calc(608 / 1080 * 100vw);
}

#m_brand_des {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#m_brand_des_content {
  width: calc(420 / 1080 * 100vw);
  display: flex;
  flex-direction: column;
}

#m_brand_des_content_icon {
  width: calc(28 / 1080 * 100vw);
  height: calc(18 / 1080 * 100vw);
  margin-bottom: calc(16 / 1080 * 100vw);
}

#m_brand_des_content_text {
  font-size: calc(40 / 1080 * 100vw);
  font-weight: bold;
  color: #333333;
  text-align: center;
}
</style>